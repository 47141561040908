<template>
  <!--
    Used to manage the collection elements like flags that are used in videoPostprocessing.
  -->
  <div class="collectionElementsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(collectionElements)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(collectionElements)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="keyFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="keyFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('keyInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="openImageViewer(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                />
                <span>Show</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
        <template
          slot="imageTemplate"
          slot-scope="{props}"
        >
          <td class="img-container">
            <img
              v-if="props.dataItem.url"
              height="42"
              loading="lazy"
              :src="props.dataItem.url"
            >
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <CollectionElementsAdd
          v-if="addingElement"
          :collection-id="collectionId"
          @reload="reloadCollectionElements"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeCollectionElement()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="imageViewerModal"
      title="<i class='fas fa-video mr-2'></i><span>Preview</span>"
    >
      <img
        v-if="imageUrl"
        loading="lazy"
        :src="imageUrl"
        class="img-fluid"
      >
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';


export default {
  name: "CollectionElementsList",
  components: {
    SweetModal,
    CollectionElementsAdd: () => import('@/components/VideoPostprocessing/ImageCollections/CollectionElementsAdd.vue'),
    Sidebar
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      collectionElements: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      deleteIndex: null,
      imageUrl: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          filterable: false,
          title: 'Preview',
          cell: 'imageTemplate',
          width: '100px',
          field: 'url'
        },
        {
          field: 'key',
          filterable: true,
          filter: 'text',
          title: 'Key',
          filterCell: "keyFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '300px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: 'Image Collection Elements'
    }
  },
  created () {
    this.getCollectionElements();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    openImageViewer (collectionElement) {
      this.imageUrl = collectionElement.url; 
      this.$refs.imageViewerModal.open();
    },
    //#endregion

    //#region API-calls
    reloadCollectionElements () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getCollectionElements();
    },
    openConfirmModal (index) {
      this.deleteIndex = index; 
      this.$refs.confirmDelete.open();
    },
    removeCollectionElement () {
      this.axios.delete(`/VideoPostProcessing/DeleteImageCollectionElement?collectionElementId=${ this.deleteIndex }`)
        .then(() => {
          this.getCollectionElements();
          this.$emit("reloadAuditLogs");
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getCollectionElements () {
      this.loading = true;
      this.axios.get(`/VideoPostProcessing/GetAllImageCollectionElements/${ this.collectionId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.collectionElements = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>

<style scoped>
  .img-container {
    text-align: center;
  }
</style>