var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collectionElementsList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.collectionElements),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.collectionElements),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"keyFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"keyFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a name"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('keyInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.openImageViewer(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"eye"}}),_c('span',[_vm._v("Show")])],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.openConfirmModal(props.dataItem.id)}}},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"trash"}}),_c('span',[_vm._v("Remove")])],1)])])]}},{key:"imageTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{staticClass:"img-container"},[(props.dataItem.url)?_c('img',{attrs:{"height":"42","loading":"lazy","src":props.dataItem.url}}):_vm._e()])]}}])}),(_vm.showSidebar)?_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.close}},[(_vm.addingElement)?_c('CollectionElementsAdd',{attrs:{"collection-id":_vm.collectionId},on:{"reload":_vm.reloadCollectionElements}}):_vm._e()],1):_vm._e()],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"title":_vm.$t('introOutroListComp.deleteElement'),"icon":"warning","blocking":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('introOutroListComp.sureToDelete')))]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmDelete.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t('cancel'))+" ")],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeCollectionElement()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_vm._v(_vm._s(_vm.$t('delete'))+" ")],1),_c('div',{staticClass:"clearfix"})]),_c('SweetModal',{ref:"imageViewerModal",attrs:{"title":"<i class='fas fa-video mr-2'></i><span>Preview</span>"}},[(_vm.imageUrl)?_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":_vm.imageUrl}}):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }